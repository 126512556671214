<script>
	import { validateEmail, validateField } from '$lib/fieldValidation.js';

	const items = [
		{
			section_name: 'INFO',
			items: [
				{
					name: 'About Us',
					url: '/our-story'
				},
				{
					name: 'Our Story',
					url: '/our-story'
				},
				{
					name: 'Our Purpose',
					url: '/our-purpose'
				},{
				name:"Contact us",
			url:"/support"	
			}
			]
		},
		{
			section_name: 'RESOURCES',
			items: [
				{
					name: 'Blog',
					url: '/communitychronicle'
				},
				{
					name: 'FAQs',
					url: '/faqs'
				}
			]
		},
		{
			section_name: 'LAND MARKETPLACE',
			items: [
				{
					name: 'Available Properties',
					url: '/properties'
				},
				{
					name: 'Sell Land',
					url: '/sell'
				},
				{
					name: 'Business Opportunities',
					url: '/b2b'
				},
				{
					name: 'The Process',
					url: '/buying-process'
				}
			]
		}
	];

	let year = new Date().getFullYear();
	let firstName;
	let lastName;
	let width;

	let email;
	let hidden_email;
	let subscribed = false;
	let errorMsg = false;
	async function sendSubscription() {
		if (
			hidden_email ||
			!email ||
			!firstName ||
			!lastName ||
			validateField(firstName) ||
			validateField(lastName)
		) {
			errorMsg = 'All fields are required!';
			return false;
		} else if (validateEmail(email)) {
			errorMsg = 'Email is not valid!';
			return false;
		}
		let url = import.meta.env.VITE_SALESFORCE_SUB;
		fetch(url, {
			method: 'POST',
			mode: 'no-cors',
			body: JSON.stringify({
				email: email,
				Subscriber_First_Name__c: firstName,
				Subscriber_Last_Name__c: lastName
			})
		});
		email = '';
		subscribed = true;
	}
</script>

<svelte:window bind:innerWidth={width} />

<footer>
	<div class="top">
		<div class="logo-holder">
		  <a href="/">
		  	<img src="/cl-circle-logo.png" alt="Loading..." />
		  </a>	
		</div>
		<div class="email-holder">
			<h3>JOIN OUR MAILING LIST</h3>
			<div>
				<div class="input-holder">
					<div class="name-input">
						<input type="text" bind:value={firstName} placeholder="Enter First Name" />
						<input type="text" bind:value={lastName} placeholder="Enter Last Name" />
					</div>
					<input type="email" bind:value={email} placeholder="Enter Email" />
				</div>
				<button id="sign-up" on:click={sendSubscription}>SIGN UP</button>
			</div>
		</div>
	</div>
	<div class="bottom">
		<div class="socials">
			<div>
			<a id="bottom-logo">
					<img src="/cl-logo-footer.png" alt="" id="footer-logo" />
			</a>	

				<div class="social-holder">
					<a href="https://www.facebook.com/CommunityLands/" target="_blank">
						<div class="icon">
							<svg
								width="35"
								height="35"
								viewBox="0 0 39 39"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39C30.2696 39 39 30.2696 39 19.5C39 8.73045 30.2696 0 19.5 0ZM21.5325 20.3565V30.9656H17.143V20.357H14.95V16.701H17.143V14.506C17.143 11.5235 18.3813 9.75 21.8995 9.75H24.8284V13.4063H22.9976C21.6281 13.4063 21.5375 13.9173 21.5375 14.8708L21.5325 16.7006H24.8492L24.4611 20.3565H21.5325Z"
									fill="#667779"
								/>
							</svg>
						</div>
					</a>
					<a href="https://www.instagram.com/communitylands/" target="_blank">
						<div class="icon">
							<svg
								width="35"
								height="35"
								viewBox="0 0 39 39"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39C30.2696 39 39 30.2696 39 19.5C39 8.73045 30.2696 0 19.5 0ZM15.2127 9.16283C16.322 9.11235 16.6765 9.1 19.501 9.1H19.4977C22.3231 9.1 22.6763 9.11235 23.7856 9.16283C24.8928 9.21353 25.649 9.38882 26.312 9.646C26.9966 9.91142 27.5751 10.2668 28.1536 10.8453C28.7322 11.4233 29.0875 12.0036 29.354 12.6876C29.6097 13.3489 29.7852 14.1046 29.8372 15.2118C29.887 16.3211 29.9 16.6756 29.9 19.5001C29.9 22.3246 29.887 22.6782 29.8372 23.7876C29.7852 24.8943 29.6097 25.6503 29.354 26.3117C29.0875 26.9956 28.7322 27.5758 28.1536 28.1539C27.5758 28.7324 26.9964 29.0886 26.3126 29.3542C25.6509 29.6114 24.8943 29.7867 23.7871 29.8374C22.6778 29.8879 22.3244 29.9002 19.4997 29.9002C16.6754 29.9002 16.3211 29.8879 15.2118 29.8374C14.1048 29.7867 13.3489 29.6114 12.6872 29.3542C12.0036 29.0886 11.4233 28.7324 10.8455 28.1539C10.2672 27.5758 9.91186 26.9956 9.64601 26.3115C9.38904 25.6503 9.21375 24.8945 9.16283 23.7873C9.11257 22.678 9.1 22.3246 9.1 19.5001C9.1 16.6756 9.113 16.3209 9.16262 15.2116C9.21245 14.1048 9.38795 13.3489 9.64579 12.6874C9.91229 12.0036 10.2676 11.4233 10.8461 10.8453C11.4242 10.267 12.0044 9.91164 12.6885 9.646C13.3497 9.38882 14.1055 9.21353 15.2127 9.16283Z"
									fill="#667779"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M18.568 10.9742C18.7492 10.9739 18.9441 10.974 19.1544 10.9741L19.501 10.9742C22.2778 10.9742 22.607 10.9842 23.7035 11.034C24.7175 11.0804 25.2679 11.2498 25.6345 11.3921C26.1198 11.5806 26.4658 11.806 26.8296 12.17C27.1936 12.534 27.419 12.8807 27.6079 13.366C27.7503 13.7322 27.9199 14.2825 27.9661 15.2965C28.0159 16.3929 28.0267 16.7222 28.0267 19.4977C28.0267 22.2733 28.0159 22.6026 27.9661 23.6989C27.9197 24.7129 27.7503 25.2633 27.6079 25.6295C27.4194 26.1148 27.1936 26.4604 26.8296 26.8242C26.4656 27.1882 26.12 27.4135 25.6345 27.602C25.2683 27.745 24.7175 27.914 23.7035 27.9604C22.6072 28.0102 22.2778 28.021 19.501 28.021C16.724 28.021 16.3949 28.0102 15.2985 27.9604C14.2845 27.9136 13.7342 27.7441 13.3673 27.6018C12.882 27.4133 12.5353 27.188 12.1713 26.824C11.8073 26.4599 11.582 26.1141 11.3931 25.6286C11.2507 25.2624 11.081 24.7121 11.0349 23.6981C10.9851 22.6017 10.9751 22.2724 10.9751 19.4951C10.9751 16.7179 10.9851 16.3903 11.0349 15.2939C11.0813 14.2799 11.2507 13.7296 11.3931 13.363C11.5816 12.8776 11.8073 12.531 12.1713 12.1669C12.5353 11.8029 12.882 11.5776 13.3673 11.3887C13.7339 11.2457 14.2845 11.0767 15.2985 11.0301C16.2579 10.9868 16.6297 10.9738 18.568 10.9716V10.9742ZM25.0525 12.701C24.3635 12.701 23.8045 13.2594 23.8045 13.9486C23.8045 14.6376 24.3635 15.1966 25.0525 15.1966C25.7415 15.1966 26.3005 14.6376 26.3005 13.9486C26.3005 13.2596 25.7415 12.7006 25.0525 12.7006V12.701ZM14.1601 19.5001C14.1601 16.5507 16.5514 14.1593 19.5008 14.1592C22.4503 14.1592 24.841 16.5506 24.841 19.5001C24.841 22.4496 22.4505 24.8399 19.501 24.8399C16.5515 24.8399 14.1601 22.4496 14.1601 19.5001Z"
									fill="#667779"
								/>
								<path
									d="M19.501 16.0334C21.4155 16.0334 22.9677 17.5854 22.9677 19.5001C22.9677 21.4146 21.4155 22.9668 19.501 22.9668C17.5863 22.9668 16.0343 21.4146 16.0343 19.5001C16.0343 17.5854 17.5863 16.0334 19.501 16.0334Z"
									fill="#667779"
								/>
							</svg>
						</div>
					</a>
					<a href="https://www.linkedin.com/company/community-lands" target="_blank">
						<div class="icon">
							<svg
								width="35"
								height="35"
								viewBox="0 0 39 39"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39C30.2696 39 39 30.2696 39 19.5C39 8.73045 30.2696 0 19.5 0ZM9.36131 16.1508H13.7804V29.4284H9.36131V16.1508ZM14.0715 12.0434C14.0428 10.7416 13.1118 9.75 11.6001 9.75C10.0883 9.75 9.1 10.7416 9.1 12.0434C9.1 13.3183 10.0591 14.3385 11.5427 14.3385H11.571C13.1118 14.3385 14.0715 13.3183 14.0715 12.0434ZM24.6294 15.839C27.5374 15.839 29.7175 17.7371 29.7175 21.8154L29.7173 29.4284H25.2984V22.3248C25.2984 20.5406 24.6589 19.3231 23.0592 19.3231C21.8383 19.3231 21.1111 20.1439 20.7917 20.9366C20.6749 21.2207 20.6462 21.6166 20.6462 22.0134V29.4287H16.2265C16.2265 29.4287 16.2848 17.397 16.2265 16.1511H20.6462V18.0318C21.2327 17.1277 22.2832 15.839 24.6294 15.839Z"
									fill="#667779"
								/>
							</svg>
						</div>
					</a>
					<a href="https://twitter.com/CommunityLands" target="_blank">
						<div class="icon">
							<svg
								width="35"
								height="35"
								viewBox="0 0 39 39"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39C30.2696 39 39 30.2696 39 19.5C39 8.73045 30.2696 0 19.5 0ZM18.9569 16.5246L18.916 15.8498C18.7932 14.101 19.8708 12.5037 21.5757 11.884C22.2032 11.6637 23.2671 11.6361 23.9627 11.8289C24.2355 11.9115 24.7538 12.1869 25.1221 12.4348L25.7905 12.8892L26.527 12.6551C26.9362 12.5312 27.4818 12.3247 27.7273 12.1869C27.9592 12.063 28.1638 11.9942 28.1638 12.0355C28.1638 12.2696 27.6591 13.0682 27.2363 13.5089C26.6634 14.1286 26.8271 14.1836 27.9865 13.7705C28.6821 13.5364 28.6957 13.5364 28.5593 13.7981C28.4775 13.9358 28.0547 14.4177 27.6046 14.8584C26.8407 15.6157 26.7998 15.6984 26.7998 16.3318C26.7998 17.3095 26.3361 19.3475 25.8723 20.4629C25.013 22.556 23.1716 24.7179 21.3302 25.8058C18.7387 27.3343 15.2878 27.7198 12.3825 26.8248C11.4141 26.5218 9.75 25.7507 9.75 25.613C9.75 25.5717 10.2547 25.5166 10.8685 25.5028C12.1506 25.4753 13.4328 25.1172 14.5239 24.4838L15.2605 24.0432L14.4148 23.754C13.2145 23.3409 12.137 22.3907 11.8642 21.4957C11.7823 21.2065 11.8096 21.1927 12.5734 21.1927L13.3646 21.1789L12.6962 20.8622C11.9051 20.4629 11.1822 19.7881 10.8275 19.0996C10.5684 18.6039 10.241 17.3508 10.3365 17.2544C10.3638 17.2131 10.6502 17.2957 10.9776 17.4059C11.9187 17.7501 12.0415 17.6675 11.4959 17.0892C10.4729 16.0426 10.1592 14.4866 10.6502 13.0132L10.8821 12.3522L11.7823 13.2473C13.6237 15.0512 15.7925 16.1252 18.2749 16.442L18.9569 16.5246Z"
									fill="#667779"
								/>
							</svg>
						</div>
					</a>

					<a href="https://www.youtube.com/@communitylands" target="_blank">
						<div class="icon">
							<svg
								width="35"
								height="35"
								viewBox="0 0 33 33"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16.8579 0.325439C8.04848 0.325439 0.903931 7.47001 0.903931 16.2794C0.903931 25.0888 8.04848 32.2333 16.8579 32.2333C25.6673 32.2333 32.8118 25.0888 32.8118 16.2794C32.8118 7.47001 25.6715 0.325439 16.8579 0.325439ZM26.8134 21.3157C26.575 22.2151 25.8722 22.9262 24.9771 23.1646C23.3583 23.5996 16.8579 23.5996 16.8579 23.5996C16.8579 23.5996 10.3617 23.5996 8.73868 23.1646C7.84352 22.9262 7.14078 22.2151 6.90235 21.3157C6.46732 19.6844 6.46731 16.2836 6.46731 16.2836C6.46731 16.2836 6.46732 12.8828 6.90235 11.2514C7.14078 10.3521 7.84352 9.64098 8.73868 9.40255C10.3575 8.96752 16.8579 8.96751 16.8579 8.96751C16.8579 8.96751 23.3541 8.96752 24.9771 9.40255C25.8722 9.64098 26.575 10.3521 26.8134 11.2514C27.2485 12.8828 27.2484 16.2836 27.2484 16.2836C27.2484 16.2836 27.2485 19.6844 26.8134 21.3157Z"
									fill="#94C852"
								/>
								<path
									d="M14.7329 19.3704L20.1624 16.2834L14.7329 13.1921V19.3704Z"
									fill="#94C852"
								/>
							</svg>
						</div>
					</a>

					<a href="https://www.pinterest.com/CommunityLands/" target="_blank">
						<div class="icon">
							<svg
								width="35"
								height="35"
								viewBox="0 0 33 33"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16.5147 0C7.39444 0 0 7.38241 0 16.4878C0 23.4749 4.34867 29.4467 10.4916 31.8481C10.3424 30.5449 10.2202 28.5364 10.5453 27.1136C10.8436 25.825 12.4764 18.9185 12.4764 18.9185C12.4764 18.9185 11.9876 17.9277 11.9876 16.4756C11.9876 14.1816 13.3198 12.4732 14.9771 12.4732C16.39 12.4732 17.0696 13.5324 17.0696 14.7941C17.0696 16.2047 16.1724 18.323 15.6958 20.29C15.3022 21.9325 16.5244 23.2747 18.1427 23.2747C21.0784 23.2747 23.3347 20.1802 23.3347 15.7313C23.3347 11.7826 20.4942 9.02729 16.4291 9.02729C11.726 9.02729 8.96867 12.5416 8.96867 16.1779C8.96867 17.5884 9.51133 19.1089 10.1909 19.9362C10.3278 20.0997 10.34 20.2486 10.2984 20.4121C10.1762 20.927 9.89022 22.0545 9.83645 22.2839C9.768 22.5816 9.592 22.65 9.27911 22.5011C7.216 21.5347 5.92533 18.5353 5.92533 16.1071C5.92533 10.9089 9.70444 6.13289 16.8398 6.13289C22.5622 6.13289 27.0209 10.2036 27.0209 15.658C27.0209 21.3443 23.4324 25.9153 18.458 25.9153C16.786 25.9153 15.2093 25.0465 14.6789 24.0166C14.6789 24.0166 13.8502 27.1648 13.6449 27.9385C13.2782 29.3759 12.2711 31.1672 11.5916 32.2679C13.1413 32.7438 14.7718 33 16.4853 33C25.6056 33 33 25.6176 33 16.5122C33.0269 7.38241 25.6324 0 16.5147 0Z"
									fill="#94C852"
								/>
							</svg>
						</div>
					</a>
				</div>
			</div>
		</div>
		<div class="links">
			{#each items as item}
				<div class="link-section">
					<p>{item.section_name}</p>
					{#each item.items as aitem}
						<a href={aitem.url}>{aitem.name}</a>
					{/each}
				</div>
			{/each}
		</div>
		<div id="copyright">
			<p>Copyright &copy; {year} Community Lands Inc</p>
			<a href="/terms">|Terms & Conditions</a>
			<a href="/privacy">|Privacy Policy</a>
		</div>
	</div>
</footer>

<style>
	.top {
		display: flex;
		justify-content: center;
		align-items: center;
		column-gap: 3rem;
		height: 300px;
		background-color: var(--white-color);
	}

	.name-input {
		display: flex !important;
		flex-direction: row !important;
		margin-bottom: 1rem;
		column-gap: 1rem;
	}

	.name-input input{
		width: 168px !important;
	}

	.email-holder h3 {
		color: var(--light-green);
		font-size: 39.84px;
		font-weight: 100;
		margin-bottom: 1rem;
		margin-top: 0;
	}

	.email-holder input {
		border-radius: 3rem;
		line-height: 37px;
		padding: 0 21px;
		width: 25rem;
		border: 1px solid black;
	}

	.email-holder input:focus {
		outline: 1px solid var(--orange-color);
	}

	.email-holder button {
		border: none;
		height: 42px;
		width: 144px;
		background-color: var(--orange-color);
		color: var(--pure-white);
		margin-top: 1.3rem;
		letter-spacing: 3px;
	}

	.input-holder {
		position: relative;
	}

	.email-holder div {
		display: flex;
		flex-direction: column;
	}

	.logo-holder {
		height: 100%;
		display: flex;
		align-items: center;
	}

	.logo-holder img {
		width: 150px;
		height: 150px;
	}

	#sign-up:hover,
	:global(.social-holder a:hover) {
		opacity: 0.8;
		transition: 300ms ease;
	}

	.bottom {
		display: grid;
		justify-content: center;
		align-items: center;
		column-gap: 3rem;
		height: 300px;
		background-color: #bfc2a5;
		grid-template-columns: 18% 22%;
	}

	.social-holder {
		display: flex;
		column-gap: 1rem;
	}

	:global(.social-holder path) {
		fill: var(--primary-color);
	}

	#footer-logo {
		width: 180px;
		height: 67px;
	}

	.links {
		display: flex;
		column-gap: 4rem;
		width: 500px;
		font-weight: 600;
	}

	.link-section {
		display: flex;
		flex-direction: column;
	}

	.link-section a,
	.link-section p {
		text-decoration: unset;
		color: var(--primary-color);
	}

	.link-section a:hover,
	#copyright a:hover {
		text-decoration: underline;
		transition: 300ms ease;
	}

	#copyright {
		display: flex;
		color: var(--pure-white);
		width: 825px;
		justify-content: center;
		align-items: center;
		column-gap: 0.3rem;
	}

	#copyright a {
		text-decoration: none;
		color: var(--pure-white);
	}

	.socials {
		height: 100%;
		display: flex;
		align-items: end;
	}

	#bottom-logo {
		cursor:pointer;
	}

	@media only screen and (max-width: 1400px) {
		#copyright {
			justify-content: left;
		}	

	}

	@media only screen and (max-width: 1100px) {
		.bottom {
			grid-template-columns: 27% 40%;
		}

		.link-section p,
		.link-section a {
			text-wrap: nowrap;
		}
	}

	@media only screen and (max-width: 890px) {
		.bottom {
			grid-template-columns: 51%;
			height: unset;
		}

		.socials {
			justify-content: center;
			margin-top: 1rem;
		}
	}

	@media only screen and (max-width: 760px) {
		.email-holder h3 {
			font-size: 39.84px;
		}
	}

	@media only screen and (max-width: 740px) {
		.bottom {
			grid-template-columns: 63%;
		}

		#copyright {
			width: unset;
			text-wrap: nowrap;
			font-size: 12px;
		}
	}

	@media only screen and (max-width: 700px) {
		.name-input {
			flex-direction: column !important;
			row-gap: 1rem;
		}

		.email-holder input {
			width: unset;
		}

		.logo-holder img {
			width: 100%;
		}

		.name-input input{
			width: unset !important;
		}
	}

	@media only screen and (max-width: 670px) {
		.email-holder h3 {
			font-size: 34.84px;
		}
	}

	@media only screen and (max-width: 640px) {
		.link-section {
			width: 150px;
		}
		.links {
			flex-direction: column;
			width: unset;
		}

		.email-holder h3 {
			font-size: 29.84px;
		}

		.top {
			column-gap: 1.5rem;
		}

		.socials {
			justify-content: unset;
		}
	}

	@media only screen and (max-width: 540px) {
		.email-holder h3 {
			font-size: 26.84px;
		}

		#copyright {
			justify-content: unset;
		}
	}

	@media only screen and (max-width: 510px) {
		.email-holder h3 {
			font-size: 22.84px;
		}

		#copyright {
			display: flex;
			flex-direction: column;
			text-wrap: wrap;
		}

		#copyright p {
			margin-bottom: 0;
		}
	}

	@media only screen and (max-width: 480px) {
		.logo-holder {
			display: none;
		}

		.email-holder input {
			width: 100%;
		}

		.name-input input {
			width: 100% !important;
		}

		.email-holder div {
			align-items: center;
		}
	}

	@media only screen and (max-width: 400px) {
		.bottom {
			grid-template-columns: 80%;
		}
	}
</style>
