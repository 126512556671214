export const mainItems = [
	{
		priority: 1,
		title: "LAND MARKETPLACE",
		subMenu: true,
		subItems: [
			{
				title: "AVAILABLE",
				target: "/properties"
			},
			{
				title: "IN CONTRACT",
				target: "/properties/in-contract"
			},
			{
				title: "SOLD",
				target: "/properties/sold"
			}
		]
	},
	{
		priority: 2,
		title: "BUYING PROCESS",
		target: "/buying-process"
	},

	{
		priority: 3,
		title: "EDUCATION",
		target: '/communitychronicle'
	},
	{
		priority: 4,
		title: "CONTACT US",
		target: '/contact'
	}
]
