<script>
	import FaAngleDown from 'svelte-icons/fa/FaAngleDown.svelte';
	import FaUserCog from 'svelte-icons/fa/FaUserCog.svelte';
	import { userData, isAuth } from '../../stores/user.js';
	import { page } from '$app/stores';
	import { fetchAPI } from '../fetchAPI.js';
	import MenuButton from './MenuButton.svelte';
	import { mainItems } from './baseItems.js';
	import { clickOutside } from '../clickOutside.js';
	import { onMount } from 'svelte';
	import { goto } from '$app/navigation';
	import Promo from './_Promo.svelte';

	$: showAdmin = $userData.access_level >= 10;
	let loaded = false;
	const singleWidth = 220;
	let width;
	let showFullSearch = false;

	let toggleMyCommunity = false;

	async function handleLogout() {
		let res = await fetchAPI('/users/logout', 'DELETE', null, true);
		if (res.ok) {
			isAuth.set('loading');
			userData.set({ access_level: 0 });
			if ($page.url.pathname.startsWith('/me')) {
				toggleMyCommunity = false;
				goto('/login');
			}
		}
	}

	let opened = false;

	let widthAvail;
	let numLinks;

	onMount(() => {
		widthAvail = width - 500;
		numLinks = Math.floor(widthAvail / singleWidth);
		loaded = true;
	});

	$: if (loaded && width) {
		widthAvail = width - 600;
		numLinks = Math.floor(widthAvail / singleWidth);
		numLinks = numLinks > 4 ? 4 : numLinks;
	}

	let mainMenuItems;
	let sideNavItems;
	$: if (numLinks > 0) {
		mainMenuItems = mainItems.slice(0, numLinks);
		sideNavItems = mainItems.slice(numLinks, mainItems.length);
	} else {
		mainMenuItems = false;
		sideNavItems = mainItems;
	}

	let showSubProps = false;
	function handleSubMenu(exists) {
		if (!exists) return;
		showSubProps = !showSubProps;
		if (opened == true && showSubProps == true && innerWidth > 767) {
			opened = false;
		}
	}

	function handleOpened() {
		opened = !opened;
	}

	//Close submenus etc. on navigate
	$: if ($page.url.pathname) {
		showSubProps = false;
		opened = false;
	}

	let height;

	$: pagePath = $page.url.pathname;
	// TODO: Change name of navigation items once its done
</script>

<Promo />
<svelte:window bind:innerWidth={width} bind:scrollY={height} />
<main class:other-page={pagePath != '/' && pagePath != '/communitychronicle'}>
	<!-- Meta Pixel Code -->
	<script>
		!function(f,b,e,v,n,t,s) {
			if(f.fbq) return; n=f.fbq=function() {
				n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
			};
			if(!f._fbq) f._fbq=n; n.push=n; n.loaded=!0; n.version='2.0';
			n.queue=[]; t=b.createElement(e); t.async=!0;
			t.src=v; s=b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t,s);
		}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
		fbq('init', '1711461976261540'); 
		fbq('track', 'PageView');
	</script>
	<noscript>
		<img height="1" width="1" style="display:none"
			 src="https://www.facebook.com/tr?id=1711461976261540&ev=PageView&noscript=1"/>
	</noscript>
	<!-- End Meta Pixel Code -->
	<div
		class:showFull={opened}
		on:click|stopPropagation={() => (opened = false)}
		on:keypress|stopPropagation={() => (opened = false)}
		class="sideWrap"
	/>
	{#if numLinks < 3}
		<div class:show={opened} class="sideNav">
			{#each sideNavItems as item}
				{#if item.subMenu}
					<button on:click={() => handleSubMenu(item.subMenu)}>
						{item.title}
						<span class="downChevron">
							<FaAngleDown />
						</span>
					</button>
				{:else}
					<a href={item.target}>
						{item.title}
					</a>
				{/if}
				{#if item.subMenu}
					<div class="sideSubCom" class:expand={showSubProps}>
						{#each item.subItems as subIt}
							<a href={subIt.target}>{subIt.title}</a>
						{/each}
					</div>
				{/if}
			{/each}

			<div class="socials">
				<a rel="noreferrer" href="https://www.facebook.com/CommunityLands/" target="_blank">
					<div class="icon">
						<img src="/fb.svg" alt="Facebook" />
					</div>
				</a>
				<a rel="noreferrer" href="https://www.instagram.com/communitylands/" target="_blank">
					<div class="icon">
						<img src="/ig.svg" alt="Instagram" />
					</div>
				</a>
				<a rel="noreferrer" href="https://www.linkedin.com/company/community-lands" target="_blank">
					<div class="icon">
						<img src="/ln.svg" alt="LinkedIn" />
					</div>
				</a>
				<a rel="noreferrer" href="https://facebook.com" target="_blank">
					<div class="icon">
						<img src="/tw.svg" alt="Twitter" />
					</div>
				</a>
			</div>
		</div>
	{/if}
	<nav class="left">
		<div class="btn" class:side-menu={width > 1309}>
			<MenuButton bind:opened on:click={handleOpened} />
		</div>
		{#if width > 754}
			<a id="logo" href="/">
				<img
					hidden={showFullSearch}
					src="/cl-primary-horizontal-fresh-pine-rgb-498px@300ppi.png"
					alt="Community Lands logo"
				/>
			</a>
		{/if}
		{#if mainMenuItems}
			<div class="mainItems">
				{#each mainMenuItems as item}
					{#if item.subMenu}
						<button
							class="mainItem"
							class:active={pagePath === item.target ||
								pagePath === '/' ||
								pagePath.includes('/properties')}
							on:click={() => handleSubMenu(item.subMenu)}
						>
							{item.title}
							<span class="downChevron">
								<FaAngleDown />
							</span>
						</button>
					{:else}
						<a class="mainItem" href={item?.target} class:active={pagePath === item.target}>
							{item.title}
						</a>
					{/if}
					{#if item.subMenu}
						<div
							use:clickOutside
							on:click_outside={() => (showSubProps = false)}
							class="subCom"
							id="subMain"
							class:scale={showSubProps}
						>
							{#each item.subItems as subIt}
								<a href={subIt.target}>{subIt.title}</a>
							{/each}
						</div>
					{/if}
				{/each}
			</div>

			<div
				use:clickOutside
				on:click_outside={() => (toggleMyCommunity = false)}
				class="link community"
				id="community-btn"
			>
				<button
					id="my-community"
					on:click={() => (toggleMyCommunity = !toggleMyCommunity)}
					class="link community"
				>
					My Community
					<span class="downChevron">
						<FaAngleDown />
					</span>
				</button>
				<span class="subCom" class:scale={toggleMyCommunity}>
					{#if $isAuth != 'loading'}
						<a on:click={() => (toggleMyCommunity = false)} id="profile-btn" href="/me">Profile</a>
						<button on:click={handleLogout}>Logout</button>
					{:else}
						<a on:click={() => (toggleMyCommunity = false)} id="register-btn" href="/register"
							>Register</a
						>
						<a on:click={() => (toggleMyCommunity = false)} href="/login">Login</a>
					{/if}
				</span>
			</div>
		{/if}
	</nav>
	{#if width <= 754}
		<div
			use:clickOutside
			on:click_outside={() => (toggleMyCommunity = false)}
			class="link community"
			id="community-btn"
		>
			<button
				id="my-community"
				on:click={() => (toggleMyCommunity = !toggleMyCommunity)}
				class="link community"
			>
				My Community
				<span class="downChevron">
					<FaAngleDown />
				</span>
			</button>
			<span class="subCom" class:scale={toggleMyCommunity}>
				{#if $isAuth != 'loading'}
					<a on:click={() => (toggleMyCommunity = false)} id="profile-btn" href="/me">Profile</a>
					<button on:click={handleLogout}>Logout</button>
				{:else}
					<a on:click={() => (toggleMyCommunity = false)} id="register-btn" href="/register"
						>Register</a
					>
					<a on:click={() => (toggleMyCommunity = false)} href="/login">Login</a>
				{/if}
			</span>

			{#if showAdmin}
				<a class="link admin" href="/admin"><FaUserCog /></a>
			{/if}
		</div>
	{/if}
	{#if showAdmin}
		<a class="link admin" href="/admin"><FaUserCog /></a>
	{/if}
</main>

<style>
	@font-face {
		font-family: 'Lato';
		src: url('/Lato/Lato-Regular.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
	}

	main {
		width: 100%;
		box-sizing: border-box;
		height: 100px;
		background-color: #f9f5f2;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 40px 0 20px;
		z-index: 99;
	}

	.other-page {
		position: sticky !important;
	}

	.side-menu {
		display: none !important;
	}

	.sideWrap {
		background-color: transparent;
		position: absolute;
		top: 100px;
		left: 0;
		width: 0;
		height: 0;
		z-index: 0;
	}

	.showFull {
		height: 100vh;
		width: 100vw;
	}

	.sideNav {
		position: absolute;
		top: 100px;
		left: 40px;
		width: 300px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		box-sizing: border-box;
		background-color: white;
		border: 1px solid #dadada;
		border-radius: 10px;
		scale: 0;
		transition: 0.3s ease;
		z-index: 100;
	}

	.show {
		scale: 1;
	}

	.sideNav a,
	.sideNav button {
		all: unset;
		border: none;
		border-bottom: 1px solid #dadada;
		width: 100%;
		padding: 0.8rem 2rem;
		font-weight: 600;
		font-size: 1rem;
		background-color: transparent;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}

	.sideNav a:last-child {
		border: none;
	}

	.sideNav a:hover {
		background-color: #eeeeee;
	}

	.socials {
		width: 100%;
		display: flex;
		justify-content: center;
		column-gap: 0.75rem;
		align-items: center;
		padding: 1.5rem 0;
		box-sizing: border-box;
	}

	.socials a {
		width: fit-content;
		padding: 0;
	}

	.socials a:hover .icon img {
		filter: brightness(0.5);
		color: black;
	}

	.socials .icon {
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#logo {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.mainItems {
		font-family: 'Lato', sans-serif;
		display: flex;
		padding: 0 1.5rem;
		column-gap: 14.5rem;
		position: relative;
	}

	.mainItem {
		all: unset;
		font-size: 1.05rem;
		font-weight: 600;
		display: flex;
		align-items: center;
		transition: 0.3s ease;
		border: none;
		background-color: transparent;
		cursor: pointer;
		color: var(--primary-color);
		position: relative;
	}

	.mainItem .downChevron {
		margin-bottom: -4px;
	}

	#subMain {
		left: 0;
		top: 2rem;
		align-items: flex-start;
		height: 100px;
		z-index: 999;
	}

	#subMain a {
		font-weight: 600;
		color: black;
		text-align: left;
	}

	.left {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
	}

	.left .btn {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.left img {
		width: 200px;
		margin-left: 4rem;
		margin-right: 3rem;
	}

	a {
		all: unset;
		cursor: pointer;
	}

	.link {
		font-size: 1rem;
		color: var(--text-accent);
		font-weight: 600;
		padding: 0 0.8rem 0 1.6rem;
	}

	.link:hover {
		color: #444;
	}

	.admin {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 1.1rem;
		color: #176822;
		background-color: #ffffff20;
		width: 1.1rem;
		padding: 0.7rem;
		border-radius: 50%;
		margin-right: 1rem;
		transition: 0.3s ease-out;
	}

	.admin:hover {
		background-color: #dddddddd;
		color: #176822;
	}

	.icon {
		height: 18px;
		color: grey;
	}

	.community {
		font-weight: 600;
		color: rgba(0, 0, 0, 0.551);
		display: flex;
		align-items: center;
		position: relative;
		border: none;
		cursor: pointer;
		background-color: inherit;
		font-size: 1.05rem;
		line-height: 22px;
		text-transform: capitalize;
		color: var(--primary-color);
	}

	#my-community {
		color: rgba(0, 0, 0, 0.525);
	}

	#my-community:hover {
		color: #176822;
	}

	.downChevron {
		height: 18px;
		width: 18px;
		color: #94c852;
		padding-left: 0.2rem;
	}

	.subCom {
		position: absolute;
		height: 5.125rem;
		width: 11rem;
		background-color: white;
		color: var(--text-color);
		bottom: -5.5rem;
		right: 0;
		border-radius: 0.5rem;
		border: 1px solid rgba(0, 0, 0, 0.15);
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0.5rem;
		box-sizing: border-box;
		transform: scale(0);
		transition: 0.2s;
		z-index: 999;
	}

	.scale {
		transform: scale(1);
	}

	.subCom a,
	.subCom button {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 0.5rem;
		background-color: transparent;
		font-size: 1rem;
		font-weight: 600;
		cursor: pointer;
	}

	.subCom a:hover,
	.subCom button:hover {
		background-color: #dadada;
	}

	.sideSubCom {
		display: none;
	}

	.expand {
		display: inline;
		width: 100%;
	}

	.expand a {
		padding-left: 4rem;
	}

	.expand a:last-child {
		border-bottom: 1px solid #dadada;
	}

	.active {
		text-decoration: underline;
		text-decoration-color: var(--orange-color);
		text-underline-offset: 1rem;
		text-decoration-thickness: 0.1rem;
		transition: 300ms ease;
	}

	.mainItem {
		position: relative;
	}

	.mainItem:not(.active):after {
		content: '';
		position: absolute;
		width: 100%;
		transform: scaleX(0);
		height: 2px;
		bottom: 0;
		top: 2.1rem;
		left: 0;
		background-color: var(--orange-color);
		transform-origin: bottom right;
		transition: transform 0.25s ease-out;
	}

	.mainItem:hover::after {
		transform: scaleX(1);
		transform-origin: bottom left;
	}

	#community-btn {
		position: absolute;
		right: 0;
	}

	@media only screen and (max-width: 1620px) {
		.mainItems {
			column-gap: 5rem;
		}
	}

	@media only screen and (max-width: 700px) {
		main {
			padding: 0 0.5rem;
		}

		.left {
			width: fit-content;
		}

		.left img {
			margin-left: 1rem;
		}

		.left .btn {
			width: 30px;
			height: 30px;
		}

		.sideNav {
			width: 100%;
			left: 0;
		}
	}
</style>
